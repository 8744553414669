import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Real1 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_01.jpg'
import Real2 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_02.jpg'
import Real3 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_03.jpg'
import Real4 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_04.jpg'
import Real5 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_05.jpg'
import Real6 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_06.jpg'
import Real7 from '../../../images/work/09_PG_SYIDA/PHOTOS/SY_07.jpg'
import Real1s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_01.jpg'
import Real2s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_02.jpg'
import Real3s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_03.jpg'
import Real4s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_04.jpg'
import Real5s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_05.jpg'
import Real6s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_06.jpg'
import Real7s from '../../../images/work/09_PG_SYIDA/PHOTOS/450x280/SY-SM_07.jpg'
import Process1 from '../../../images/work/09_PG_SYIDA/PROCESS/SYI_01.jpg'
import Process2 from '../../../images/work/09_PG_SYIDA/PROCESS/SYI_02.jpg'
import Process3 from '../../../images/work/09_PG_SYIDA/PROCESS/SYI_03.jpg'
import Process4 from '../../../images/work/09_PG_SYIDA/PROCESS/SYI_04.jpg'
import Process5 from '../../../images/work/09_PG_SYIDA/PROCESS/SYI_05.jpg'
import Process1s from '../../../images/work/09_PG_SYIDA/PROCESS/450x280/SYI-SM_01.jpg'
import Process2s from '../../../images/work/09_PG_SYIDA/PROCESS/450x280/SYI-SM_02.jpg'
import Process3s from '../../../images/work/09_PG_SYIDA/PROCESS/450x280/SYI-SM_03.jpg'
import Process4s from '../../../images/work/09_PG_SYIDA/PROCESS/450x280/SYI-SM_04.jpg'
import Process5s from '../../../images/work/09_PG_SYIDA/PROCESS/450x280/SYI-SM_05.jpg'

const Residence09 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PACIFIC GROVE RESIDENCE"
        residenceLocation="PACIFIC GROVE, CALIFORNIA"
        infoList={['Remodel of existing 1,300 SF single family residence. 440 SF master bedroom addition. New 370 SF "floating" observation deck.','Design - 2016', 'Construction - 2017', 'General Contractor: Wallis Construction, Inc', 'Structural Engineer: Central Coast Engineers, Inc']}
        testimonialList={['We are immensely grateful to Anatoly for his wonderful design of the remodel and addition to our home, especially the floating deck, allowing us to enjoy sunsets over the pacific ocean. Both the inside and outside spaces feel extremely comfortable, from the new master bedroom and bath to the existing bedroom extension that transformed a small, unfriendly bedroom into a comfortable one.','On the technical side Anatoly\'s estimate of the cost and time frame of the design phase (including the cost all the local governmental approvals we would need) was extremely accurate, and the plans were approved without any problems. The best part was interacting with Anatoly on a personal basis. He was always prompt and on each iteration of the design he listened to our concerns and thoughts and came up with wonderful ideas on how to solve them.','We enthusiastically give Anatoly our 5 star recommendation for anyone considering him as their Architect or friend.','Sincerely, Stephanie and Mort']}
        photos={[Real1,Real2,Real3,Real4,Real5,Real6,Real7]}
        photosSmall={[Real1s,Real2s,Real3s,Real4s,Real5s,Real6s,Real7s]}
        processPhotos={[Process1,Process2,Process3,Process4,Process5]}
        processPhotosSmall={[Process1s,Process2s,Process3s,Process4s,Process5s]}
        />
    </Container>
  </Layout>
)

export default Residence09;
